@import "~@angular/material/theming";
@include mat-core();

$app-primary: mat-palette($mat-indigo);
$app-accent: mat-palette($mat-pink, A200, A100, A400);

$app-warn: mat-palette($mat-red);

$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

@include angular-material-theme($app-theme);

:root {
  --brand: "selforder";
  /** primary **/
  --ion-color-primary: #E15F00;
	--ion-color-primary-rgb: 225,95,0;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #c65400;
	--ion-color-primary-tint: #e46f1a;

  /** secondary **/
  --ion-color-secondary: #241f21;
  --ion-color-secondary-rgb: 36, 31, 33;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #201b1d;
  --ion-color-secondary-tint: #3a3537;
  /** tertiary **/
	--ion-color-tertiary: #e15f00;
	--ion-color-tertiary-rgb: 225,95,0;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #c65400;
	--ion-color-tertiary-tint: #e46f1a;

  /** payment-success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #b32013;
  --ion-color-danger-rgb: 179, 32, 19;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #9e1c11;
  --ion-color-danger-tint: #bb362b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-main: #212121;
  --ion-color-main-rgb: 33, 33, 33;
  --ion-color-main-contrast: #ffffff;
  --ion-color-main-contrast-rgb: 255, 255, 255;
  --ion-color-main-shade: #1d1d1d;
  --ion-color-main-tint: #373737;

  --ion-color-second: #7a7a7a;
  --ion-color-second-rgb: 122, 122, 122;
  --ion-color-second-contrast: #ffffff;
  --ion-color-second-contrast-rgb: 255, 255, 255;
  --ion-color-second-shade: #6b6b6b;
  --ion-color-second-tint: #878787;

  --ion-color-gray-light: #f5f5f5;
  --ion-color-gray-dark: #d4d4d4;
  --ion-color-gray-darkest: #1f1b1c;

  --ion-color-black: #212121;
  --ion-color-gold: #a38a5f;

  --ion-default-font: AppFont, serif !important;
  --ion-text-color: var(--ion-color-black);

  --quantity-selector-border-width: 1px;
  --quantity-selector-border-color: #ececec;
  --quantity-selector-rectangle: var(--ion-color-main);
  --quantity-selector-square: var(--ion-color-main);
  --quantity-selector-font-weight: 700;
  --quantity-selector-height: 40px;
  --quantity-selector-width: 25px;
  --quantity-selector-background: white;
  --quantity-selector-text-color: var(--ion-color-primary);
  --quantity-selector-family: AppFontBold, sans-serif;

  --ion-color-payment-page: var(--ion-color-secondary);
  --border-radius-payment-modal: 0px;
  --table-selection-subtitle: #858585;

  --swiper-navigation-color: var(--ion-color-primary);

}

.ion-color-main-color {
  --ion-color-base: var(--ion-color-main);
  --ion-color-base-rgb: var(--ion-color-main-rgb);
  --ion-color-contrast: var(--ion-color-main-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-shade);
  --ion-color-tint: var(--ion-color-main-tint);
}

.ion-color-second-color {
  --ion-color-base: var(--ion-color-second);
  --ion-color-base-rgb: var(--ion-color-second-rgb);
  --ion-color-contrast: var(--ion-color-second-contrast);
  --ion-color-contrast-rgb: var(--ion-color-second-contrast-rgb);
  --ion-color-shade: var(--ion-color-second-shade);
  --ion-color-tint: var(--ion-color-second-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black);
  --ion-color-contrast: var(--ion-color-main-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-shade);
  --ion-color-tint: var(--ion-color-main-tint);
}

html,
body {
  height: 100%;
  font-family: AppFont, "Helvetica Neue", sans-serif;
}

body,
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

p {
  color: var(--ion-color-main);
}
