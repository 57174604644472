@import "../theme/mixins";

$brand: "theash";
$brandAssets: "/assets/#{$brand}";
:root {
  --brand: $brand;
  --ion-color-main: white;
  --ion-color-second: #241f21;
  --ion-color-card: #222326;
  --ion-color-text: #f4ece1;
  --ion-border-radius: 28px;
  --ion-color-black: #131313;
  --ion-color-title: #f1ebe0;
}

@font-face {
  font-family: AppFont;
  src: url("../assets/font/RobotoSlab-Regular.ttf");
}
@font-face {
  font-family: AppFontBold;
  src: url("../assets/font/RobotoSlab-Bold.ttf");
}
@font-face {
  font-family: AppFontLight;
  src: url("../assets/font/RobotoSlab-Light.ttf");
}
@font-face {
  font-family: AppFontMedium;
  src: url("../assets/font/RobotoSlab-Medium.ttf");
}
@font-face {
  font-family: Slat;
  src: url("../assets/font/Staatliches-Regular.ttf");
}
